module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://monbanquet.fr","exclude":["/404","/commande","/merci","/demande/merci"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Monbanquet.fr","short_name":"Monbanquet","start_url":"/","background_color":"#fff","theme_color":"#fc4c4c","display":"minimal-ui","icon":"static/favicons/favicon-staging.jpeg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9f9da73203871aa18090994c76982edd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
